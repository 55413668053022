<template>

  <main class="flex-shrink-0" id="app">
    <div id="main-page">
      <div class="row-sm" id="logo-container" style="margin-bottom: 5em">
          <img id="logo" alt="Vue logo" src="./assets/images/logos/Jungle_Gym_Backyard_Playgrounds_1988_RGB_big.png"
          style="width:600px; max-width:80%; height:auto">

      </div>

      <div class="row main-text">
        <h1 style="font-size: 30px; margin-bottom: 0.5em;">Download
          manual</h1>
          <h2 style="font-size: 15px; margin-bottom: .5em;">Enter your
            Config ID</h2>
      </div>
<!--      <div class="row main-text" style="margin-bottom: 1em">-->
<!--          <h2 style="font-size: 20px">Enter your Config ID</h2>-->
<!--      </div>-->

      <div class="wrapper">
        <input @keyup.enter="redirect" type="text" v-model="so"
               placeholder="Os1Ar9"/>
        <button class="btn btn-primary" @click="redirect" type="submit">Go</button>
      </div>

      <div style="margin-top: 2.5em">
<!--        <h2 style="font-size: 20px; margin-bottom: .5em;">Download your-->
<!--          manual</h2>-->
        <button class="btn btn-primary"
                style="margin-top: 2em; width:300px; height: 50px"><a
            style="font-size:20px"
            href="https://helpdesk.junglegym.com/help/en-gb/34-jungle-gym-backyard-playgrounds">Pre-designed</a></button>
      </div>


<!--      <div>-->
<!--        <JG_manuals></JG_manuals>-->
<!--      </div>-->


    </div>

  </main>



</template>

<script>
import $ from 'jquery';
// import JG_manuals from "./components/wysiwyg-manuals.vue";

export default {
  name: 'App',
  // components: {
  //   JG_manuals
  // },
  data() {
    return {
      so: ""
    }
  },
  methods: {
    redirect() {
      const url = "https://my.junglegym.com/" + this.so;
      $.ajax({
        url: url,
        dataType: 'jsonp', 
        statusCode: {
          200: function() {
            console.log( "Great" );
            window.location.href = url;

          },
          404: function() {
            alert("Invalid Config ID")
          }
        },
        error:function(){
          console.log("Error");
        }
      });
    }
  }
}
</script>

<style>

#logo-container {
  position: start;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 5vh;*/
}

#main-page {
  /*margin-left: 15%;*/
  /*margin-right: 15%;*/
  background-color: #eeebdd;
  bottom: 0;
}

.wrapper {
  margin: 0 auto;
  justify-content: center;
  text-align: center;

}

input[type=text] {
    padding:5px; 
    border:2px solid #ccc; 
    -webkit-border-radius: 5px;
    border-radius: 5px;
    max-width: 60%;
}

input[type=text]:focus {
    border-color:#333;
}

button {
    padding:5px 15px;
    background:#ccc;
    border:0 none;
    cursor:pointer;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    height: 36px
}

img{
  max-width:80%;
}

body {background-color: #eeebdd;}


#logo-container {
    display: flex;
    justify-content: center;

}

#logo {
    padding: 5%;
}

</style>
